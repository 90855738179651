import PropTypes from 'prop-types'
import React from 'react'
import tw from 'twin.macro'
import { HTMLType, StyleType } from '../utils/prop-types'
import Button from './button'
import Heading from './heading'
import Link from './link'
import { global } from '../styles/global'

const ContactCard = ({ label, contactType, value, subtext, style }) => {
  const getValueComponent = () => {
    switch (contactType) {
      case 'URL':
        return (
          <Button
            link={value}
            label={subtext}
            type="primary"
            size="base"
            theme="white-cyan"
            style={tw`bg-primary-50 text-primary-500`}
            hideCaret
          />
        )
/*       case 'Email':
        return (
          <Link to={`mailto:${value}`} style={global`base.hover-underline`()}>
            <Heading content={value} headingType="h4" style={tw`font-normal text-primary-500`} />
          </Link>
        ) */
      case 'Email':
      return (
        <Heading content={value} headingType="h4" style={tw`font-normal text-secondary-500`} />
      ) 
      case 'Text':
      default:
        return <Heading content={value} headingType="h4" style={tw`font-normal text-secondary-500`} />
    }
  }

  return (
    <div css={[tw`flex flex-col items-start`, style]}>
      <Heading
        content={label}
        headingType="h6"
        style={tw`mb-4 font-normal text-primary-500 `}
      />
      {getValueComponent()}
    </div>
  )
}

ContactCard.defaultProps = {
  contactType: 'Text',
}

ContactCard.propTypes = {
  label: PropTypes.string,
  contactType: PropTypes.string,
  value: PropTypes.string,
  subtext: HTMLType,
  style: StyleType,
}

export default ContactCard
