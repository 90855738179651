import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import tw, { css } from 'twin.macro'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { global } from '../styles/global'
import { HTMLType, ImageType, LinkType, StyleType } from '../utils/prop-types'
import Animation from './animation'
import Button from './button'
import Heading from './heading'

const themeToStyle = (theme) => {
  switch (theme) {
    case 'white':
      return tw`text-white`
    case 'cyan':
      return tw`text-secondary-500`
    case 'navy':
    default:
      return tw`text-primary-500`
  }
}

const AnimatedHero = ({
  style,
  contentStyle,
  animation,
  animationOptions,
  title,
  description,
  callToAction,
  theme,
  buttonTheme,
  extended,
  isFull,
}) => {
  const textColor = useMemo(() => themeToStyle(theme))
  const carouselSettings = {
    dots: false,
    arrows: false,
    fade: true,
    infinite: true,
    autoplay: true,
    pauseOnHover: false,
    speed: 1000,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <div
      css={[
        css`
          ${tw`relative bg-transparent`}
          ${extended && tw`-mt-18 lg:(-mt-20)`}
          strong {
            ${tw`font-medium`}
          }
        `,
        style,
      ]}
    >
      <div css={[tw`relative h-screen`]}>
        {isFull ? <div css={tw`w-full h-full`}>
          <Animation
            url={animation.url}
            style={tw`h-full`}
            options={{
              loop: false,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMin slice',
              },
              ...animationOptions,
            }}
          />
        </div> :
        <div css={tw`w-full h-full`}>
          <Animation
            url={animation.url}
            style={tw`h-78vh lg:(h-full)`}
            options={{
              loop: false,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMin slice',
              },
              ...animationOptions,
            }}
          />
        </div> }
        <div
          css={[
            global`layout.container`,
            global`layout.grid`,
            tw`absolute inset-x-0 top-38 lg:(top-auto bottom-1/5)`,
            contentStyle,
          ]}
        >
          <div
            css={tw`flex flex-col items-start col-span-4 md:col-span-6 xl:col-span-7 xxl:col-span-6`}
          >
            <Heading
              headingType="h1"
              content={title}
              style={[
                css`
                  p {
                    ${tw`font-light`}
                    ${textColor}
                  }
                  span {
                    ${tw`font-medium`}
                  }
                `,
              ]}
            />
            {
            Array.isArray(description) ? (
              <div css={tw`w-full mt-8`}>
                <Slider {...carouselSettings}>
                    {description?.map((text, i) => (
                        <Heading
                          key={i}
                          headingType="h4"
                          content={text.textNode}
                          style={[textColor, tw`font-normal`]}
                        />
                    ))}
                </Slider>
              </div>
            )
            : (
              <Heading
                headingType="h4"
                content={description}
                style={[textColor, tw`mt-8 font-thin`]}
              />
            )
          }
            {callToAction && (
              <Button
                type="primary"
                size="sm"
                theme={buttonTheme}
                label={callToAction.label}
                link={callToAction.link}
                style={tw`mt-8 lg:mt-12`}
                hideCaret
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

AnimatedHero.defaultProps = {
  animationOptions: {},
  theme: 'navy',
  buttonTheme: 'navy-white',
}

AnimatedHero.propTypes = {
  style: StyleType,
  contentStyle: StyleType,
  animation: ImageType,
  animationOptions: PropTypes.object,
  title: HTMLType,
  description: PropTypes.oneOfType([PropTypes.array, HTMLType]),
  callToAction: LinkType,
  theme: PropTypes.oneOf(['white', 'navy', 'cyan']),
  buttonTheme: PropTypes.string,
  extended: PropTypes.bool,
  isFull: PropTypes.bool,
}

export default AnimatedHero
